// 考评档案填制列表页配置文件
export var SearchList = [
  { labe: '档案编号', code: 'number', type: 'input' },
  { labe: '档案描述', code: 'message', type: 'input' },
  { labe: '考评季度', code: 'quarter', type: 'input' },
  {
    labe: '档案状态',
    code: 'status',
    type: 'select',
    option: [
      { label: '新建', value: '1' },
      { label: '计算中', value: '2' },
      { label: '计算完成', value: '3' },
      { label: '计算失败', value: '4' },
      { label: '评分中', value: '5' },
      { label: '汇总完成', value: '6' },
      { label: '已发布', value: '7' },
      { label: '已作废', value: '8' }
    ]
  },
  {
    labe: '是否有效',
    code: 'isEffective',
    type: 'select',
    option: [
      { label: '是', value: 'true' },
      { label: '否', value: 'false' }
    ]
  },
  {
    labe: '公司',
    code: 'companyCode',
    type: 'select',
    option: []
  },
  {
    labe: '建档日期',
    code: 'jdRq',
    type: 'daterange'
  }
]
export var SearchData = {
  number: '',
  message: '',
  quarter: '',
  status: '',
  isEffective: '',
  companyCode: '',
  jdRq: ''
}
export var tableField = [
  // { type: 'input', selectType: 'selection', width: '', reserveSelection: true },
  {
    label: '档案状态',
    code: 'status',
    type: 'function',
    width: '80%',
    handle: (index, data) => {
      return data.status === 1 ? '新建' : data.status === 2 ? '计算中' : data.status === 3 ? '计算完成' : data.status === 4 ? '计算失败' : data.status === 5 ? '评分中' : data.status === 6 ? '汇总完成' : data.status === 7 ? '已发布' : data.status === 8 ? '已作废' : ''
    }
  },
  { label: '档案编号', code: 'number', type: 'input', width: '150%' },
  { label: '档案描述', code: 'message', type: 'input', width: '250%' },
  { label: '考评季度', code: 'quarter', type: 'input', width: '80%' },
  { label: '考评日期从', code: 'startDate', type: 'input', width: '160%' },
  { label: '考评日期至', code: 'endDate', type: 'input', width: '160%' },
  // { label: '公司名称', code: 'companyName', type: 'input', width: '100%' },
  { label: '建档人', code: 'createUserId', type: 'input', width: '90%' },
  { label: '建档时间', code: 'createDate', type: 'input', width: '160%' },
  {
    label: '操作',
    code: 'act',
    width: '60%',
    type: 'action',
    actionData: [
      {
        actionLabel: '查看',
        operationButton: 'see'
      }
    ]
  }
]

export var tableData = []
