<template>
  <div class="list">
    <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
    <global-table :tableField="tableField" @tableAction="tableAction" @SelectionChange='SelectionChange' :tableData="tableData" :paginationData="paginationData" @pageChange="pageChange"
    ></global-table>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { SearchList, SearchData, tableField, tableData } from './js/setting.js'
import { request } from '@/assets/js/http.js'
import dayjs from 'dayjs'
export default {
  components: Component.components,
  name: 'FileFilling',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      tableField: tableField,
      tableData: tableData,
      selectTabData: [],
      paginationData: {
        pageSize: pageSize,
        total: 0
      },
      pageNum: 1,
      menudata: this.$store.state.ViewMenuData.menuData,
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },
  created () {
    this.myBidData()
  },

  methods: {
    // 获取考评档案填制数据列表
    myBidData () {
      let cjksTime = ''
      let cjjsTime = ''
      if (this.childData.searchData.jdRq) {
        cjksTime = dayjs(this.childData.searchData.jdRq[0]).format('YYYY-MM-DD HH:mm:ss')
        cjjsTime = dayjs(this.childData.searchData.jdRq[1]).format('YYYY-MM-DD 23:59:59')
      }
      const obj = {
        number: this.childData.searchData.number,
        message: this.childData.searchData.message,
        quarter: this.childData.searchData.quarter,
        status: this.childData.searchData.status,
        isEffective: this.childData.searchData.isEffective,
        companyCode: this.childData.searchData.companyCode,
        startDate: cjksTime,
        endDate: cjjsTime
      }
      request('/api/evaluation/evaluation/queryAll?' + 'pageNum=' + this.pageNum + '&' + 'pageSize=' + this.paginationData.pageSize, 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          if (this.paginationData.total !== res.data.total) {
            this.paginationData.total = res.data.total
          }
        }
      })
    },

    // 点击操作里的查看按钮
    tableAction: function (index, data, clickBtn) {
      if (clickBtn.operationButton === 'see') {
        this.$router.push({ params: { viewName: 'FileFillingDetails' }, query: { id: data.id } })
      }
    },

    // 新建档案
    addfile () {
      console.log('新建档案')
      this.$router.push({
        path: '/MainData/AddFiled'
      })
    },

    // 作废
    toVoid () {
      this.$message({
        showClose: true,
        type: 'warning',
        message: '操作成功'
      })
      // this.myBidData()
    },

    // 作废弹框
    toVoidBox (data) {
      if (this.selectTabData.length === 0) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请选择要作废的数据'
        })
      } else {
        this.$confirm('确认要作废吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.toVoid()
        })
      }
    },

    // 获取选中的数据
    SelectionChange (data) {
      this.selectTabData = data
    },

    // 查询
    onSearch (data) {
      this.pageNum = 1
      this.myBidData()
    },
    // 清空
    clearSearch (data) {
      this.pageNum = 1
      this.myBidData()
    },
    // 分页
    pageChange (data) {
      this.pageNum = data
      this.myBidData()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tablePadding.scss';
.list{
  .exportData{
    padding: 8px 30px;
  }
  .textBtn{
    font-size: 16px;
  }
  .tableList{
    margin-top: 30px;
  }
}
</style>
